import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { StoredRecipe } from "./storage";
import CopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadIcon from "@mui/icons-material/Publish";

export default function Recipe({
  recipe,
  loadRecipe,
  shareRecipe,
  deleteRecipe,
  migrateRecipe,
}: {
  recipe: StoredRecipe;
  loadRecipe: () => void;
  shareRecipe: () => void;
  deleteRecipe: () => void;
  migrateRecipe: () => void;
}) {
  return (
    <Card key={recipe.name}>
      <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          sx={{ textAlign: "left" }}
          color="text.secondary"
        >
          {recipe.name}
        </Typography>
        {recipe.modified_at && (
          <Typography
            variant="body2"
            sx={{ marginTop: "auto" }}
            color="text.secondary"
          >
            Last modified: {new Date(recipe.modified_at).toLocaleDateString()}
          </Typography>
        )}
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={loadRecipe}
          variant="outlined"
          size="small"
          startIcon={<LoadIcon />}
        >
          Load
        </Button>
        <Button
          onClick={shareRecipe}
          color="secondary"
          variant="outlined"
          size="small"
          startIcon={<CopyIcon />}
        >
          Copy share string
        </Button>
        <Button
          onClick={migrateRecipe}
          color="error"
          variant="outlined"
          size="small"
        >
          DONT CLICK THIS
        </Button>
        <Button
          onClick={deleteRecipe}
          color="warning"
          variant="outlined"
          size="small"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
}
