import { createOrRetrieveFoodByUrl } from "../pages/addFoodToNutriboom";
import { StoredRecipe } from "../pages/storage";
import { supabase } from "../supabase";

export default async function migrateRecipe(
  userUid: string,
  recipe: StoredRecipe
) {
  const fetchedData = await Promise.all(
    recipe.recipe.map(async ([url, multiplier]) => {
      const result = await createOrRetrieveFoodByUrl(url);
      return [result.uuid, multiplier] as const;
    })
  );

  await supabase
    .from("cookbook")
    .insert({
      name: recipe.name,
      uuid: recipe.uid,
      user_uuid: userUid,
    })
    .select();

  await Promise.all(
    fetchedData.map(async ([foodUuid, multiplier]) => {
      return await supabase
        .from("ingredient")
        .insert({
          food_uuid: foodUuid,
          multiplier,
          recipe_uuid: recipe.uid,
          user_uuid: userUid,
        })
        .select();
    })
  );
}

async function createRecipe(userUid: string, recipe: StoredRecipe) {
  return supabase
    .from("cookbook")
    .insert({
      name: recipe.name,
      uuid: recipe.uid,
      user_uuid: userUid,
    })
    .select();
}

async function createFoods(recipe: StoredRecipe) {
  return Promise.all(
    recipe.recipe.map(async ([url, multiplier]) => {
      const result = await createOrRetrieveFoodByUrl(url);
      return [result.uuid, multiplier] as const;
    })
  );
}

export async function migrateRecipeAsync(
  userUid: string,
  recipe: StoredRecipe
) {
  createRecipe(userUid, recipe)
    .then(() => createFoods(recipe))
    .then((foods) =>
      Promise.all(
        foods.map(([foodUuid, multiplier]) => {
          return supabase
            .from("ingredient")
            .insert({
              food_uuid: foodUuid,
              multiplier,
              recipe_uuid: recipe.uid,
              user_uuid: userUid,
            })
            .select();
        })
      )
    );
}
