import { Stack, Typography } from "@mui/material";

type FigureProps = {
  title: string;
  numerator: number | string;
  denominator: number | string;
};
export default function Figure({ title, numerator, denominator }: FigureProps) {
  return (
    <Stack>
      <Typography fontWeight={600} align="center">
        {title}
      </Typography>
      <Stack direction="row">
        <Typography>{numerator}</Typography>
        <Typography marginX={1}>/</Typography>
        <Typography>{denominator}</Typography>
      </Stack>
    </Stack>
  );
}
