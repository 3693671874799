import { Grid, Snackbar } from "@mui/material";
import Recipe from "./Recipe";
import { useSavedRecipes } from "./queries";
import { unzip } from "lodash";
import { useState } from "react";
import { supabase } from "../supabase";
import useInvalidateQuery from "./useInvalidateQuery";
import { migrateRecipeAsync } from "../migration/migrateRecipe";

type RecipeViewProps = {
  userUid: string;
  setUrls: (urls: string[]) => void;
  setMultipliers: (multipliers: number[]) => void;
  setCookedAmount: (cookedAmount: number) => void;
};

export default function RecipeView({
  userUid,
  setUrls,
  setMultipliers,
  setCookedAmount,
}: RecipeViewProps) {
  const [showShareToast, setShowShareToast] = useState(false);

  const invalidateRecipes = useInvalidateQuery("recipes");
  const { data: recipes } = useSavedRecipes();

  return (
    <Grid
      sx={{
        marginLeft: "1rem",
        marginRight: "1rem",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showShareToast}
        onClose={() => setShowShareToast(false)}
        autoHideDuration={6000}
        message="Share string has been copied to clipboard!"
      />
      {recipes &&
        recipes.map((recipe, index) => (
          <Recipe
            key={index}
            loadRecipe={() => {
              const [urls, quantities] = unzip(recipe.recipe) as [
                string[],
                number[]
              ];
              setUrls(urls);
              setMultipliers(quantities);
              if (recipe.cooked_amount) {
                setCookedAmount(recipe.cooked_amount);
              }
            }}
            shareRecipe={() => {
              navigator.clipboard.writeText(btoa(JSON.stringify(recipe)));
              setShowShareToast(true);
            }}
            deleteRecipe={async () => {
              supabase
                .from("recipes")
                .delete()
                .eq("uid", recipe.uid)
                .then(invalidateRecipes);
            }}
            migrateRecipe={() => migrateRecipeAsync(userUid, recipe)}
            recipe={recipe}
          />
        ))}
    </Grid>
  );
}
