import parse from "node-html-parser";
import { FoodData } from "../types";

function transformMacro(value: string) {
  switch (value) {
    case "Of which Polyunsaturated":
      return "polyunsaturates";
    case "Of which Saturates":
      return "saturates";
    case "Of which Sugars":
      return "sugars";
    default:
      return value.toLowerCase();
  }
}

const GRAM_REGEX = /(\d*)\s{0,}g/;
const MILLILITRE_REGEX = /(\d*)\s{0,}ml/;

export default function transformNutritionData(htmlBody: string): FoodData {
  const output = parse(htmlBody);
  const title = output
    .getElementsByTagName("title")[0]
    .innerText.replace("| Waitrose &amp; Partners", "")
    .replace("&amp;", "&");
  const tables = output.getElementsByTagName("table");

  const tableHeading = tables[0].getElementsByTagName("thead")[0].childNodes[0];

  const shouldBeTypicalValues = tableHeading.childNodes[0].innerText;
  let quantity = tableHeading.childNodes[1].innerText;

  if (shouldBeTypicalValues !== "Typical values") {
    console.warn("Typical values is not matched");
  }
  console.info("Quantity", quantity);
  if (quantity !== "Per 100g") {
    console.warn("Amount is not per 100g");
  }
  if (!quantity) {
    console.info("Trying alt quantity");
    quantity = tableHeading.childNodes[2].innerText;
  }

  const gramMatch = quantity.match(GRAM_REGEX);
  const millilitreMatch = quantity.match(MILLILITRE_REGEX);

  const quantityData =
    gramMatch && gramMatch.length === 2
      ? {
          amount: parseFloat(gramMatch[1]),
          unit: "g",
        }
      : millilitreMatch && millilitreMatch.length === 2
      ? {
          amount: parseFloat(millilitreMatch[1]),
          unit: "ml",
        }
      : undefined;

  const tableRows = tables[0].getElementsByTagName("tbody")[0].childNodes;
  const result = Object.fromEntries(
    tableRows.map((child: any) => {
      const key = transformMacro(child.childNodes[0].innerText);
      const value = child.childNodes[1].innerText
        .replace("kcal", "")
        .replace("Kcal", "")
        .replace(",", ".")
        .replace("&lt;", "")
        .replace("g", "");
      return [key, value];
    })
  );
  return {
    ...result,
    quantity: quantityData!,
    title,
  } as FoodData;
}
