import { create } from "zustand";
import getCookie from "../pages/getCookie";

type V2State = {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
};

export const useV2 = create<V2State>((set) => {
  const enabled = (getCookie("v2") || "") === "true";
  return {
    enabled,
    setEnabled: (enabled: boolean) => {
      set({ enabled });
    },
  };
});
