import { useQuery } from "@tanstack/react-query";
import { sortBy } from "lodash";
import { supabase } from "../../supabase";

export type CookbookEntry = {
  uuid: string;
  user_uuid: string;
  name: string;
};

export function useCookbook() {
  return useQuery({
    queryKey: ["cookbook"],
    queryFn: async () =>
      sortBy(
        (await supabase.from("cookbook").select<"*", CookbookEntry>()).data,
        "name"
      ),
  });
}

type LoadedFood = {
  name: string;
  ingredient_name: string;
  multiplier: number;
  unit: string;
  amount: number;
  calories: number;
  carbohydrates: number;
  fat: number;
  fibre: number;
  protein: number;
  salt: number;
  saturates: number;
  sugars: number;
};
export function useCookbookItem(uuid: string) {
  return useQuery({
    queryKey: ["cookbook", uuid],
    queryFn: async () =>
      transformLoadedFood(
        (
          await supabase.rpc<"get_cookbook_item", LoadedFood>(
            "get_cookbook_item",
            {
              uuid,
            }
          )
        ).data as Array<LoadedFood>
      ),
  });
}

function transformLoadedFood(food: Array<LoadedFood>) {
  const ingredients = food
    .sort((a, b) => b.multiplier - a.multiplier)
    .map((item) => item.ingredient_name);
  const normalizedNutrition = normalize(food);
  return {
    name: food[0].name,
    ingredients,
    ...normalizedNutrition,
  };
}

function normalize(food: Array<LoadedFood>) {
  const totalWeight = food
    .map((item) => item.amount * item.multiplier)
    .reduce((a, b) => a + b, 0);
  const nutrition = food.reduce(
    (acc, item) => {
      const multiplier = item.multiplier;
      acc.calories += item.calories * multiplier;
      acc.protein += item.protein * multiplier;
      acc.carbohydrates += item.carbohydrates * multiplier;
      acc.fat += item.fat * multiplier;
      acc.fibre += item.fibre * multiplier;
      acc.salt += item.salt * multiplier;
      acc.saturates += item.saturates * multiplier;
      acc.sugars += item.sugars * multiplier;
      return acc;
    },
    {
      calories: 0,
      protein: 0,
      carbohydrates: 0,
      fat: 0,
      fibre: 0,
      salt: 0,
      saturates: 0,
      sugars: 0,
    }
  );
  return {
    calories: normalizeIngredient(nutrition.calories, totalWeight),
    protein: normalizeIngredient(nutrition.protein, totalWeight),
    carbohydrates: normalizeIngredient(nutrition.carbohydrates, totalWeight),
    fat: normalizeIngredient(nutrition.fat, totalWeight),
    fibre: normalizeIngredient(nutrition.fibre, totalWeight),
    salt: normalizeIngredient(nutrition.salt, totalWeight),
    saturates: normalizeIngredient(nutrition.saturates, totalWeight),
    sugars: normalizeIngredient(nutrition.sugars, totalWeight),
  };
}

function normalizeIngredient(figure: number, total: number) {
  return Math.round(figure / (total / 100));
}
