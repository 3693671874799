import { Avatar, Box, Paper, Stack, Typography, styled } from "@mui/material";
import { useCookbookItem } from "./useCookbook";
import Figure from "../../components/Figure";
import VerticalDivider from "../../components/VerticalDivider";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

type CookbookItemProps = {
  uuid: string;
};
export default function CookbookItem({ uuid }: CookbookItemProps) {
  const { data } = useCookbookItem(uuid);

  return (
    <Item>
      <Stack direction="row" spacing={5} sx={{ alignItems: "center" }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: "center" }}
          minWidth={"220px"}
        >
          <Avatar>
            {data && data.name.split(" ")[0][0]}
            {data && data.name.split(" ")[1][0]}
          </Avatar>
          <Typography>{data && data.name}</Typography>
        </Stack>
        <VerticalDivider />
        {data && (
          <Figure
            title={"Calories"}
            numerator={data.calories}
            denominator={"100g"}
          />
        )}
        <VerticalDivider />
        {data && (
          <Figure
            title={"Protein"}
            numerator={data.protein}
            denominator={"100g"}
          />
        )}
        <VerticalDivider />
        <Typography>{data && data.ingredients.join(", ")}</Typography>
      </Stack>
    </Item>
  );
}
