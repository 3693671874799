import { GoogleLogin } from "@react-oauth/google";
import { supabase } from "../../supabase";

export default function LoginWithGoogle() {
  return (
    <GoogleLogin
      theme="filled_blue"
      onSuccess={async (response) => {
        await supabase.auth.signInWithIdToken({
          provider: "google",
          token: response.credential || "",
        });
      }}
      onError={() => {
        // TODO: This we should handle better
        console.log("Login Failed");
      }}
    />
  );
}
