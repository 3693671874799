import HeaderBar from "./header/HeaderBar";
import RecipeEditor from "./recipe-editor/RecipeEditor";
import Cookbook from "./cookbook/Cookbook";

export default function Main() {
  return (
    <div>
      <HeaderBar />
      <RecipeEditor />
      <Cookbook />
    </div>
  );
}
