import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useDarkMode } from "../../state/darkModeState";

export default function DarkModeToggle() {
  const darkMode = useDarkMode((state) => state.darkMode);
  const setDarkMode = useDarkMode((state) => state.setDarkMode);
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={!darkMode}
              onChange={() => setDarkMode(!darkMode)}
            />
          }
          label="Dark Mode"
        />
      </FormGroup>
    </Box>
  );
}
