import { Stack, Typography } from "@mui/material";
import { useCookbook } from "./useCookbook";
import CookbookItem from "./CookbookItem";

export default function Cookbook() {
  const { data: cookbook } = useCookbook();

  return (
    <div>
      <Typography marginLeft={1} marginBottom={2} variant="h4">
        Cookbook
      </Typography>
      <Stack gap={1} paddingX={1}>
        {cookbook?.map((recipe) => (
          <CookbookItem key={recipe.uuid} uuid={recipe.uuid} />
        ))}
      </Stack>
    </div>
  );
}
