import { useQuery } from "@tanstack/react-query";
import { supabase } from "../supabase";
import transformNutritionData from "./transformNutritionData";
import { Food, Unit } from "./types";

export function useFood(uuid: string): Food | undefined {
  const { data } = useQuery({
    queryKey: ["food"],
    queryFn: async () =>
      (await supabase.from("food").select<"*", Food>().eq("uuid", uuid)).data,
  });
  return data?.length === 1 ? data[0] : undefined;
}

export async function createOrRetrieveFoodByUrl(url: string): Promise<Food> {
  const existingResponse = (
    await supabase.from("food_identifiers").select("uuid").eq("url", url)
  ).data;

  if (existingResponse && existingResponse.length === 1) {
    const dbResponse = await supabase
      .from("food")
      .select<"*", Food>("*")
      .eq("uuid", existingResponse[0].uuid);
    if (dbResponse.error) {
      throw new Error(
        "Error when trying to load food: " + JSON.stringify(dbResponse.error)
      );
    }
    // TODO: Theoretically we can get multiple matches for a url?
    return dbResponse.data[0];
  }

  const createPayload = await fetch(
    `/api/nutrition/${url.replace("https://www.waitrose.com/", "")}`
  ).then(async (res) => {
    const nutritionResponse = transformNutritionData(await res.text());
    const payload = {
      url,
      name: nutritionResponse.title || "",
      unit: (nutritionResponse.quantity.unit as Unit) || "g",
      amount: nutritionResponse.quantity.amount || 0,
      calories: nutritionResponse.energy || 0,
      fat: nutritionResponse.fat || 0,
      saturates: nutritionResponse.saturates || 0,
      carbohydrates: nutritionResponse.carbohydrate || 0,
      sugars: nutritionResponse.sugars || 0,
      fibre: nutritionResponse.fibre || 0,
      protein: nutritionResponse.protein || 0,
      salt: nutritionResponse.salt || 0,
    };
    return payload;
  });

  await supabase.rpc("create_food", createPayload);
  return await createOrRetrieveFoodByUrl(url);
}
